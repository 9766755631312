import React from 'react';

export default function IconMovie(props) {
    const { width, height, fillColor } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 24 24"
            style={{
                fill: `${fillColor}`,
            }}
        >
            <path d="M20,3H4C2.897,3,2,3.897,2,5v6v8c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-8V5C22,3.897,21.103,3,20,3z M20.001,9 C20,9,20,9,20.001,9h-0.465l-2.667-4H20L20.001,9z M9.536,9L6.869,5h2.596l2.667,4H9.536z M14.536,9l-2.667-4h2.596l2.667,4H14.536 z M4,5h0.465l2.667,4H4V5z M4,19v-8h16l0.002,8H4z" />
            <path d="M10 18L15.5 15 10 12z" />
        </svg>

    )
}
